import React, { Dispatch, SetStateAction, useState } from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Social from '../../Social/Social';
import Covid from '../../common/Covid/Covid';

import Modal from '../../common/Modal/Modal';

import { ReactComponent as Vector } from '../../Icons/vector.svg';
import { ReactComponent as CloseBtn } from '../../Icons/closeBtn.svg';
import { ReactComponent as Right } from '../../Icons/right.svg';

import './MenuModal.scss';

interface MenuProps {
	menuOpened: boolean;
	covidOpened: boolean;
	setMenuOpened: Dispatch<SetStateAction<boolean>>;
	setCovidOpened: Dispatch<SetStateAction<boolean>>;
}

export default function Menu({ menuOpened, setMenuOpened, setCovidOpened, covidOpened }: MenuProps) {
	const [shownExpertise, setShownExpertise] = useState(false);
	const [shownAbout, setShownAbout] = useState(false);

	return (
		<Modal isOpen={menuOpened} onRequestClose={() => setMenuOpened(false)} className="menuModalOverlay">
			<div className="menuModalContent">
				<div
					onClick={() => {
						setMenuOpened(false);
						setShownAbout(false);
						setShownExpertise(false);
					}}
					className="menuCloseWrapper"
				>
					<CloseBtn />
				</div>
				<div className="menuLinksWrapper">
					<div className="menuTitle">Menu</div>
					<div className="menuLinks">
						<div className="menuLinksContainer">
							<div className="menuLink">
								<Link
									onClick={() => {
										setMenuOpened(false);
									}}
									to="/about"
									className="menuLink"
								>
									{'About'}{' '}
								</Link>
								<Right
									onClick={() => {
										setShownAbout(!shownAbout);
									}}
									className={shownAbout ? 'openMenu' : 'closeMenu'}
								/>
							</div>

							{shownAbout && (
								<div className="menuLinkOpened">
									<HashLink
										to="/about#company"
										onClick={() => {
											setMenuOpened(false);
											setShownAbout(false);
										}}
										className="menuLink"
									>
										Company
									</HashLink>
									<Link
										to="/team"
										onClick={() => {
											setMenuOpened(false);
											setShownAbout(false);
										}}
										className="menuLink"
									>
										The team
									</Link>
								</div>
							)}
							<div className="menuLink">
								<Link
									onClick={() => {
										setMenuOpened(false);
									}}
									to="/services"
									className="menuLink"
								>
									{'Services'}{' '}
								</Link>
								<Right
									onClick={() => {
										setShownExpertise(!shownExpertise);
									}}
									className={shownExpertise ? 'openMenu' : 'closeMenu'}
								/>
							</div>
							{shownExpertise && (
								<div className="menuLinkOpened">
									<Link
										to="/service/recruitment"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{'Recruitment'}
									</Link>
									<Link
										to="/service/itDevelopment"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{'IT Development'}
									</Link>
									<Link
										to="/service/odooSpecialists"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{'Odoo ERP Specialists'}
									</Link>
									<Link
										to="/service/support"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{'Customer Service Support'}
									</Link>
									<Link
										to="/service/ict-management"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{'ICT Management'}
									</Link>
									<Link
										to="/service/sales"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{'Sales and Internet Marketing'}
									</Link>
									<Link
										to="/service/project-coordination"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{'Project Coordination'}
									</Link>
									<Link
										to="/service/human-resources"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{'Human Resources'}
									</Link>
									<Link
										to="/service/sales"
										onClick={() => {
											setMenuOpened(false);
											setShownExpertise(false);
										}}
										className="menuLink"
									>
										{'Sales and Internet Marketing'}
									</Link>
								</div>
							)}
							<Link
								onClick={() => {
									setMenuOpened(false);
									setShownAbout(false);
									setShownExpertise(false);
								}}
								to="/contact"
								className="menuLink"
							>
								<HashLink to="/contact#contactPlanner" className="menuLink">
									{'Contact'}
								</HashLink>
							</Link>
							<Link
								onClick={() => {
									setMenuOpened(false);
									setShownAbout(false);
									setShownExpertise(false);
								}}
								to="/myteam"
								className="menuLink"
							>
								Build a Team
							</Link>
							<Link
								onClick={() => {
									setMenuOpened(false);
									setShownAbout(false);
									setShownExpertise(false);
								}}
								to="/blog"
								className="menuLink"
							>
								Blog
							</Link>
							<Link
								onClick={() => {
									setMenuOpened(false);
									setShownAbout(false);
									setShownExpertise(false);
								}}
								to="/vacancies"
								className="menuLink"
							>
								{'Job Opening'}
							</Link>

							<Link
								onClick={() => {
									setMenuOpened(false);
									setShownExpertise(false);
								}}
								to="/joinus"
								className="menuLink"
							>
								<HashLink to="/joinus#joinUsPlanner" className="menuLink">
									Join Us
								</HashLink>
							</Link>
						</div>
					</div>
				</div>
				<div className="menuBackgraundWrapper">
					<Vector className="menuModalVector" />
				</div>
				<div className="menuModalSocialWrapper">
					<div>
						<Social />
					</div>
					<FirestoreCollection path="/infoModal/">
						{(infoData) => {
							if (infoData.isLoading) {
								return;
							}
							return infoData.value[0].shown && infoData.value[0].image.sm.src ? (
								<div className="menuModalCovidWrapper">
									<div onClick={() => setCovidOpened(true)} className="menuModalCovidButton">
										<img src={infoData.value[0].image.sm.src} alt="" />
									</div>
									{covidOpened && <Covid setCovidOpened={setCovidOpened} {...infoData.value[0]} />}
								</div>
							) : null;
						}}
					</FirestoreCollection>
				</div>
			</div>
		</Modal>
	);
}
