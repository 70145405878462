import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

// Initialize Firebase
export const config = {
	apiKey: 'AIzaSyB_7S3vuq8JV2qBv4b_L9ew7JODlPIclGo',
	authDomain: 'gssua-adm.firebaseapp.com',
	databaseURL: 'https://gssua-adm.firebaseio.com/',
	projectId: 'gssua-adm',
	storageBucket: 'gssua-adm.appspot.com',
	messagingSenderId: '647708335910',
	appId: '1:647708335910:web:009f1e4959638efe58abe7',
	measurementId: 'G-F315R5DMYW',
};

firebase.initializeApp(config);

const db = firebase.firestore();

export { db };
