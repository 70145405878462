import React from 'react';
import { Link } from 'react-router-dom';
import { FirestoreCollection } from '@react-firebase/firestore';
import { HashLink } from 'react-router-hash-link';

import Social from '../Social/Social';

import './Footer.scss';

export default function Footer() {
	return (
		<div className="footer">
			<FirestoreCollection path="/footer/">
				{(data) => {
					return (
						!data.isLoading && (
							<div className="сertificatesWrapper">
								<img className="certificateImage" src={data.value[0].footer.image.lg.src} alt=""></img>
							</div>
						)
					);
				}}
			</FirestoreCollection>
			<div className="footerLinks">
				<Link to="/sitemap" className="footerLink">
					{'Sitemap'}
				</Link>
				<Link to="/terms" className="footerLink">
					{'Terms'}
				</Link>
				<Link to="/privacy" className="footerLink">
					{'Privacy'}
				</Link>
				<HashLink to="/contact#contactPlanner" className="footerLink">
					{'Contact'}
				</HashLink>
			</div>
			<div className="footerSocialIconsWrapper">
				<Social />
			</div>
			<div className="footerEndCopyrightWrapper">
				<div className="footerEndCopyright">
					<div className="footerCopyright">
						Copyright @ 2023 GIS. <span>{'All Rights Reserved.'}</span>
					</div>
				</div>
			</div>
		</div>
	);
}
