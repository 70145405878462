import React, { Dispatch, SetStateAction, useState } from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';

import { ReactComponent as Right } from '../../Icons/right.svg';

import './Cookies.scss';

interface CookiesProps {
	setCookiesAccepted: Dispatch<SetStateAction<string>>;
	setCookiesClosed: Dispatch<SetStateAction<boolean>>;
}

export default function Cookies({ setCookiesAccepted, setCookiesClosed }: CookiesProps) {
	const [collapseCookies, setCollapseCookies] = useState(true);

	return (
		<FirestoreCollection path="/cookies/">
			{(data) => {
				if (data.isLoading) {
					return;
				}
				return (
					<div className="cookiesWrapper">
						<div className="cookiesContent">
							<div className="cookiesContentWrapper">
								<span
									dangerouslySetInnerHTML={{
										__html: `${collapseCookies ? data.value[0].shortText : data.value[0].text}`,
									}}
								></span>
								<div
									className={collapseCookies ? 'expandCookies' : 'collapsCookies'}
									onClick={() => setCollapseCookies(!collapseCookies)}
								>
									{collapseCookies ? 'See details' : 'Hide details'} <Right />
								</div>
							</div>
							<div className="cookiesButtonWrapper">
								<button
									onClick={() => {
										setCookiesAccepted('');
										setCookiesClosed(true);
									}}
									className="cookiesButton"
								>
									{data.value[0].closeButtonText}
								</button>
								<button onClick={() => setCookiesAccepted('accepted')} className="cookiesReadMore">
									{data.value[0].submitButtonText}
								</button>
							</div>
						</div>
					</div>
				);
			}}
		</FirestoreCollection>
	);
}
