import React, { Dispatch, SetStateAction } from 'react';
import { ImageResizer } from '../../../Types/ImageResizer';

import './Covid.scss';

interface CovidProps {
	setCovidOpened: Dispatch<SetStateAction<boolean>>;
	title: string;
	text: string;
	buttonText: string;
	openedImage: ImageResizer;
}

export default function Covid({ setCovidOpened, title, text, buttonText, openedImage }: CovidProps) {
	return (
		<div className="covidWrapper">
			<div className="covidContent">
				<img src={openedImage.original.src} alt="" className="openedInfoImage" />
				<div className="covidTitle">{title}</div>
				<span dangerouslySetInnerHTML={{ __html: `${text}` }}></span>
				<div>
					<button onClick={() => setCovidOpened(false)} className="covidButton">
						{buttonText}
					</button>
				</div>
			</div>
		</div>
	);
}
