import React, { useState } from 'react';
import { FirestoreCollection } from '@react-firebase/firestore';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfigs';

import { ReactComponent as Vector } from '../Icons/vector.svg';
import { ReactComponent as Alert } from '../Icons/alertTrans.svg';

import './Subscribe.scss';
import ReactDOMServer from 'react-dom/server';

interface FormData {
	email: string;
}

export default function Subscribe() {
	const { register, handleSubmit, errors } = useForm();
	const [emailTemplate, setEmailTemplate] = useState<string>('');
	const [replyEmail, setReplyEmail] = useState('');
	const [formData, setFormData] = useState<FormData>({ email: '' });
	const [sendStatus, setSendStatus] = useState<number | undefined>();
	const [disableSendButton, setDisableSendButton] = useState(false);
	const [emailTemplateSubscribe, setEmailTemplateSubscribe] = useState<string>('');
	const location = useLocation();

	const updateInput = (e: any) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
		setSendStatus(undefined);
	};
	const onSubmit = () => {
		sendEmail();
		setSendStatus(undefined);
	};
	const sendEmail = () => {
		setDisableSendButton(true);
		db.collection('subscribers')
			.add({
				email: formData.email,
				subscribe: true,
				createDate: new Date(),
			})
			.then(() => setSendStatus(200))
			.catch(() => setSendStatus(400));
		db.collection('buildEmail').add({
			to: replyEmail.split(',').map((e) => e.replace(/ /g, '')),
			message: {
				subject: 'New request on subscription has been added by website - Global Staffing Support',
				html: emailTemplate,
			},
		});
		db.collection('buildEmail').add({
			to: formData.email,
			message: {
				subject: 'You have subscribed to Global Staffing Support',
				html: emailTemplateSubscribe,
			},
		});
		setFormData({
			email: '',
		});
		setDisableSendButton(false);
	};

	return (
		<>
			{location.pathname !== '/' && (
				<div className="subscribeForm">
					<FirestoreCollection path="/subscribeForm/" limit={1}>
						{(data) => {
							if (data.isLoading) {
								return;
							}

							setReplyEmail(data.value[0].replyEmail);

							const emailTemplateSubscribe = ReactDOMServer.renderToString(
								<html>
									<head>
										<meta charSet="utf-8" />
									</head>
									<body>
										<div
											style={{
												width: '600px',
												margin: '40px auto 0',
												padding: '0 20px',
											}}
										>
											<img
												alt=""
												style={{ width: '100%' }}
												src="https://firebasestorage.googleapis.com/v0/b/gssua-adm.appspot.com/o/headRes.png?alt=media&token=d1b31d8a-c81c-4fe9-813d-f365286702d4"
											/>
											<div
												style={{
													textAlign: 'center',
													padding: '15px 20px 0',
													fontSize: '16px',
													fontWeight: 'bold',
													maxWidth: '750px',
													margin: '0 auto',
													color: '#24292e',
												}}
											>
												Thank you for subscribing! The latest vacancies will be sent to you regularly.
											</div>
										</div>
									</body>
								</html>
							);

							setEmailTemplateSubscribe(emailTemplateSubscribe);

							const template = ReactDOMServer.renderToString(
								<html>
									<head>
										<meta charSet="utf-8" />
									</head>
									<body>
										<div
											style={{
												width: '600px',
												margin: '40px auto 0',
												padding: '0 20px',
											}}
										>
											<img
												alt=""
												style={{ width: '100%' }}
												src="https://firebasestorage.googleapis.com/v0/b/gssua-adm.appspot.com/o/headRes.png?alt=media&token=d1b31d8a-c81c-4fe9-813d-f365286702d4"
											/>
											<div
												style={{
													textAlign: 'center',
													padding: '15px 20px 0',
													fontSize: '16px',
													fontWeight: 'bold',
													maxWidth: '750px',
													margin: '0 auto',
													textTransform: 'uppercase',
													color: '#24292e',
												}}
											>
												{data.value[0].title}
											</div>
											<div style={{ width: '100%' }}>
												{formData.email && (
													<div
														style={{
															maxWidth: '1150px',
															margin: '0 auto',
															padding: '20px 0',
														}}
													>
														<div
															style={{
																color: '#24292e',
																fontWeight: 'bold',
																padding: '17px 0',
																minWidth: '200px',
																fontSize: '14px',
																whiteSpace: 'nowrap',
																display: 'inline-block',
															}}
														>
															Email:
														</div>
														<div style={{ fontSize: '14px', fontWeight: 'bold', display: 'inline-block', color: '#24292e' }}>
															{formData.email}
														</div>
													</div>
												)}
											</div>
										</div>
									</body>
								</html>
							);
							return (
								<>
									<div className="subscribeFormContent">
										<div className="subscribeFormContentWrapper">
											<div className="subscribeFormTitle">{data.value[0].title}</div>
											<div className="subscribeFormSubTitle">{data.value[0].subTitle}</div>
										</div>
										<div className="subscribeFormContentWrapper">
											<form onSubmit={handleSubmit(onSubmit)} className="subscribeFormContentForm">
												<div className="subscribeFormContentInput">
													<input
														type="text"
														name="email"
														className={errors.email ? 'subscribeFormInputError' : 'subscribeFormInput'}
														placeholder={data.value[0].placeholder}
														ref={register({
															required: 'Required Email',
															pattern: {
																value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
																message: 'invalid email address',
															},
														})}
														onChange={updateInput}
														value={formData.email || ''}
													/>
													{errors.email && <Alert className="subscribeFormInputAlert" />}
												</div>
												<button
													type="submit"
													disabled={disableSendButton}
													onClick={() => setEmailTemplate(template)}
													className="subscribeFormButton"
												>
													{data.value[0].buttonText}
												</button>
											</form>
											{errors.email && <div className="subscribeFormError">{'Invalid email address'}</div>}
											{sendStatus === 200 && <div className="subscribeFormSuccess">{'Successfully'}</div>}
											{sendStatus === 400 && <div className="subscribeFormError">{'Something went wrong'}</div>}
										</div>
									</div>
									<Vector className="subscribeFormVector" />
								</>
							);
						}}
					</FirestoreCollection>
				</div>
			)}
		</>
	);
}
